import React from 'react';
import About from './Pages/About/About';
import HeaderFooterLayout from './layout/HeaderFooterLayout';
import Terms from './Pages/Terms/Terms';
import Page from './Pages/Landing Page/Page';
import Contact from './Pages/Contact/Contact';
import Policy from './Pages/Policy/Policy';
import Cost from './Pages/Cost/Cost';
import Faqs from './Pages/Faq/Faqs';
import Curriculum from './Pages/Curriculum/Curriculum';
import Form from './Pages/Form/Form';
import Courses from './Pages/Courses/Courses';
import SingleCourse from './Pages/Courses/SingleCourse';
import NotFound from './Pages/NotFound';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom';

// import Promo from './Pages/Promo/Promo';
// import Promos_payment from './Pages/Promo/Promos_payment';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="" element={<HeaderFooterLayout />}>
        <Route index element={<Page />} />
        <Route path="/Signup" element={<Form />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/Single-course" element={<SingleCourse />} />
        <Route path="/Policy" element={<Policy />} />
        <Route path="/Terms&Condition" element={<Terms />} />
        <Route path="/Pricing" element={<Cost />} />
        <Route path="/Faqs" element={<Faqs />} />
        <Route path="/Curriculum" element={<Curriculum />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </>
  )
);
function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

// function App() {
//   return (
//     <>
//       <BrowserRouter>
//         <ScrollToTop />
//         <Routes>
//           <Route
//             path="/"
//             element={
//               <RouteWithLayout Layout={HeaderFooterLayout} Component={Page} />
//             }
//           />
//           <Route
//             path="/Signup"
//             element={
//               <RouteWithLayout Layout={HeaderFooterLayout} Component={Form} />
//             }
//           />
//           {/* <Route
//             path="/Payment"
//             element={
//               <RouteWithLayout
//                 Layout={HeaderFooterLayout}
//                 Component={JapaPage}
//               />
//             }
//           /> */}

//           <Route
//             path="/About"
//             element={
//               <RouteWithLayout Layout={HeaderFooterLayout} Component={About} />
//             }
//           />
//           <Route
//             path="/Contact"
//             element={
//               <RouteWithLayout
//                 Layout={HeaderFooterLayout}
//                 Component={Contact}
//               />
//             }
//           />
//           <Route
//             path="/Courses"
//             element={
//               <RouteWithLayout
//                 Layout={HeaderFooterLayout}
//                 Component={Courses}
//               />
//             }
//           />
//           <Route
//             path="/Single-course"
//             element={
//               <RouteWithLayout
//                 Layout={HeaderFooterLayout}
//                 Component={SingleCourse}
//               />
//             }
//           />
//           <Route
//             path="/Policy"
//             element={
//               <RouteWithLayout Layout={HeaderFooterLayout} Component={Policy} />
//             }
//           />
//           {/* <Route
//                path="/Promo"
//                element={
//                  <RouteWithLayout Layout={HeaderFooterLayout} Component={Promo} />
//                }
//              />
//               <Route
//                path="/Promos"
//                element={
//                  <RouteWithLayout Layout={HeaderFooterLayout} Component={Promos_payment} />
//                }
//              /> */}
//           <Route
//             path="/Terms&Condition"
//             element={
//               <RouteWithLayout Layout={HeaderFooterLayout} Component={Terms} />
//             }
//           />
//           <Route
//             path="/Pricing"
//             element={
//               <RouteWithLayout Layout={HeaderFooterLayout} Component={Cost} />
//             }
//           />
//           <Route
//             path="/Faqs"
//             element={
//               <RouteWithLayout Layout={HeaderFooterLayout} Component={Faqs} />
//             }
//           />
//           <Route
//             path="/Curriculum"
//             element={
//               <RouteWithLayout
//                 Layout={HeaderFooterLayout}
//                 Component={Curriculum}
//               />
//             }
//           />
//         </Routes>
//       </BrowserRouter>
//     </>
//   );
// }

export default App;
