import React from "react";
import Listing from "../../components/Listing";
import Button from "../../components/Button";
import left_glow from "../../assetss/left_glow.png";
import right_glow from "../../assetss/right_glow.png";
import bottom_shade from "../../assetss/bottom_shade.png";
import left_shade from "../../assetss/left_shade.png";
import heroImg from "../../assetss/hero-img.png";
import { Link } from "react-router-dom";
import { validateDateIsInPast } from "../../utils/ValidateDateIsPast";

export default function Hero() {
  // const [showModal, setShowModal] = React.useState(false);

  // React.useEffect(() => {
  // 	const timer = setTimeout(() => {
  // 		setShowModal(true);
  // 	}, 3000);

  // 	return () => clearTimeout(timer);
  // }, []);
  return (
    <>
      <section className="relative lg:h-full overflow-hidden">
        <div className="relative lg:px-[81px] lg:pt-[230px] px-[20px] pt-[20px] flex justify-center items-center h-[100%] flex-col">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="flex flex-col items-start pt-48 lg:pt-10 pb-20">
              <h1 className="text-white font-semibold lg:text-6xl md:text-4xl text-3xl lg:leading-[72px] mb-6">
                Advance your Career in{" "}
                <span className="text-[#FDCA01] font-bold">Cloud DevOps</span>.
              </h1>
              <p className="text-white lg:leading-[28px] lg:mb-[70px] md:mb-[80px] mb-[90px] lg:text-lg text-sm">
                Set yourself up for success by registering for our Cloud DevOps
                Program. We will provide you with the right mentorship, tools,
                and content through Social and Project Based Learning.
              </p>
              <Link to="/Courses" className="links w-full md:w-auto">
                <Button
                  text="View Courses"
                  custom="bg-[#FDCA01] text-black w-full"
                />
              </Link>
            </div>
            <div className="flex items-end lg:pt-20">
              <img src={heroImg} className="" alt="" />
            </div>
          </div>
        </div>
        <img className="z-[-1] absolute top-0" src={left_glow} alt="" />
        <img
          className="z-[-2] absolute bottom-0 right-0"
          src={bottom_shade}
          alt=""
        />
        <img
          className="z-[-4] absolute left-0 bottom-0"
          src={left_shade}
          alt=""
        />
        <img
          className="z-[-3] absolute right-0 bottom-0"
          src={right_glow}
          alt=""
        />
      </section>
      <section className="bg-black/10 lg:px-[81px] border-y border-y-white/50 py-[20px] md:px-[10px] text-center">
        <div className="md:grid md:grid-cols-4 grid grid-cols-1 justify-evenly md:mb-4 mb-7 px-4">
          <Listing
            upper="Next Cohort Starts:"
            lower={validateDateIsInPast("2024-5-7")}
          />
          <Listing upper="Duration:" lower="5 months" />
          <Listing upper="Learning Mode:" lower="Online" />
          <div className="">
            <Link to="/Pricing" className="links w-full md:w-auto">
              <button className=" border duration-300 border-yellow-500 text-[#FDCA01] bg-transparent font-medium lg:px-[60px] px-[20px] py-[15px] rounded-[50px] w-full">
                View Cost Breakdown
              </button>
            </Link>
          </div>
        </div>
        {/* {showModal ? (
					<>
						<Modal
							handlClick={() => {
								setShowModal(false);
							}}
						/>
					</>
				) : null} */}
      </section>
    </>
  );
}
