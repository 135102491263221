import React from "react";
import flyer from "../../assetss/event.jpg";
import { Link } from "react-router-dom";

const Webinar = () => {
  return (
    <section className="bg-black">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:px-[81px] px-[20px] py-40">
        <div>
          <div className="bg-[#141021] border border-neutral-400 2xl:max-w-lg rounded-lg p-4">
            <img src={flyer} className="" alt="webinar" />
          </div>
        </div>
        <div>
          <h4 className="gradient-text text-sm mb-2">UPCOMING EVENTS</h4>
          <h2 className="lg:text-5xl md:text-4xl text-3xl text-white font-semibold leading-normal mb-4">
            Great News!!!
            {/* <span className="text-[#FDCA01] block">You Asked, we answered</span> */}
          </h2>
          <p className="text-base lg:text-lg text-[#A1A1A1] leading-normal mb-5">
            Schull.io invites you to our exclusive webinar,{" "}
            <span className="text-[#FDCA01]">"Grow and Earn in Tech."</span>{" "}
            Come and learn the latest trends, tools, and strategies to advance
            your career and increase your income.
          </p>
          <p className="text-base lg:text-lg text-[#A1A1A1] leading-normal mb-5">
            You can expect Insights from industry experts, tips for advancing
            your tech career, and networking opportunities with like-minded
            professionals.
          </p>
          <div className="mb-10">
            <p className="text-base lg:text-lg text-[#FDCA01] leading-normal">
              Date: 27th April
            </p>
            <p className="text-base lg:text-lg text-[#FDCA01] leading-normal">
              Time: 7p.m
            </p>
            <p className="text-base lg:text-lg text-[#FDCA01] leading-normal">
              Venue:{" "}
              <a
                className="underline underline-offset-4"
                href="http://surl.li/syjub"
              >
                http://surl.li/syjub
              </a>
            </p>
          </div>
          <p className="text-base lg:text-lg text-[#A1A1A1] leading-normal mb-10">
            Don't miss out! Register now and take the first step toward a
            brighter tech future.
          </p>
          <Link
            className="hover:cursor-pointer border duration-300 border-yellow-500 font-medium lg:px-[60px] px-[20px] py-[15px] rounded-[50px] bg-[#FDCA01] text-black"
            to={"/Signup"}
          >
            Register
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Webinar;
