import React from "react";

export default function SelectInput({label, name, handleChange, value, options}) {
  return (
    <label>
      {label} <br />
      <select
        className="w-full bg-black text-gray-500 lg:py-3 lg:px-5 md:px-2 md:py-3 px-2 py-2 rounded-lg mt-2 outline-none"
        name={name}
        id={name}
        onChange={handleChange}
        value={value}
      >
        <option value="" className="">-Select-</option>
        {options.map((item,index)=>{
            return <option key={index} value={item}>{item}</option>
        })}
      </select>
    </label>
  );
}
