import React, { useState } from "react";
import logo from "../assets/logo2.svg";
import call from "../assets/call.svg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

export default function Navbar() {
  const [data, setData] = React.useState({});
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };
  const location = useLocation();
  const route = location.pathname;

  const api_Key = "67273a00-5c4b-11ed-9204-d161c2da74ce";
  async function getUserData() {
    try {
      const response = await axios.get(
        `https://geolocation-db.com/json/${api_Key}`
      );
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(function () {
    getUserData();
  }, []);
  return (
    <>
      <nav
        className={
          route == "/cityboy/training"
            ? " hidden"
            : " w-full fixed z-30 bg-[#141021]"
        }
      >
        {/* <div className="bg-[#FDCA01] flex items-center justify-center h-[49px] text-[14px] md:px-0 px-2 text-center">
          <p className="md:text-base text-xs">
            DevOps Demystified: <strong>From Basics to Brilliance</strong>
          </p>
          <a
            target="__blank"
            className="md:ml-5 ml-2 border border-white md:px-[30px] px-3 md:py-[3px] py-[1px] rounded-[50px] hover:cursor-pointer bg-yellow-400 hover:bg-yellow-500 hover:text-black duration-300 font-medium"
            href="https://us02web.zoom.us/meeting/register/tZIucOurpjoiHtwSzdNw7N54QaH3Y1Jqtt9v"
          >
            Register
          </a>
        </div> */}
        <section className="lg:px-[81px] lg:py-[20px] md:px-[30px] md:py-[40px] px-[20px] py-[20px] text-[#FFFFFF80]  flex justify-between items-center">
          <div className="lg:w-[122.18px] lg:h-[24px] md:w-[150px] w-[122.18px]">
            <Link to="/" className="links">
              <img className="w-full" src={logo} alt="schulltech" />
            </Link>
          </div>

          <div onClick={handleNav} className="block lg:hidden z-2">
            {nav ? (
              <AiOutlineClose size={30} className="text-white" />
            ) : (
              <AiOutlineMenu size={30} className="text-white" />
            )}
          </div>

          <ul className="hidden lg:flex align-middle items-center justify-between">
            <Link to="/About" className="links">
              <li className="lg:ml-[50px] ml-[20px] hover:cursor-pointer hover:text-white">
                About Schull.io
              </li>
            </Link>
            <Link to="/Courses" className="links">
              <li className="lg:ml-[50px] ml-[20px] hover:cursor-pointer hover:text-white">
                Courses
              </li>
            </Link>
            <Link to="/Pricing" className="links">
              <li className="lg:ml-[50px] ml-[20px] hover:cursor-pointer hover:text-white">
                Pricing
              </li>
            </Link>
            <Link to="/Curriculum" className="links">
              <li className="lg:ml-[50px] ml-[20px] hover:cursor-pointer hover:text-white">
                Curriculum
              </li>
            </Link>
          </ul>

          <ul className="hidden lg:flex items-center space-x-6">
            <Link to="/Contact" className="links">
              <li className="flex items-center space-x-2 hover:cursor-pointer text-[#FDCA01]">
                <img src={call} className="w-5 h-5" alt="phone" />
                <span>Contact Us</span>
              </li>
            </Link>
            <li className="hover:cursor-pointer border border-[#FFFFFF80] px-[40px] py-[8px] rounded-[50px] hover:text-white">
              <a href="https://portal.schull.io/" className="">
                Login
              </a>
            </li>

            {data.country_name !== "Nigeria" ? (
              ""
            ) : (
              <Link to="/Signup" className="links">
                <li className="hover:cursor-pointer border border-yellow-500 px-[40px] py-[8px] rounded-[50px] hover:bg-yellow-500 hover:text-black duration-300">
                  Sign Up
                </li>
              </Link>
            )}
          </ul>

          <div
            className={
              nav
                ? "z-[1] translate-y-[-90px] h-[100vh] w-full bg-[#141021] text-white absolute top-[90px] left-0 flex transition-all duration-500 items-center justify-center text-center"
                : "absolute translate-y-[-90px] h-[100vh] w-full bg-black bg-opacity-20 text-white top-[90px] left-[-100%] transition-all duration-[500ms] flex items-center justify-center text-center overflow-hidden"
            }
          >
            <ul className="md:text-[20px]">
              <Link to="/About" className="links" onClick={handleNav}>
                <li className="my-[10px] mb-[30px] hover:cursor-pointer">
                  About Us
                </li>
              </Link>
              <Link to="/Contact" className="links" onClick={handleNav}>
                <li className="mb-[30px] hover:cursor-pointer">Contact Us</li>
              </Link>
              <Link to="/Courses" className="links" onClick={handleNav}>
                <li className="mb-[30px] hover:cursor-pointer">Courses</li>
              </Link>
              <Link to="/Pricing" className="links" onClick={handleNav}>
                <li className="mb-[30px] hover:cursor-pointer">Pricing</li>
              </Link>
              <Link to="/Curriculum" className="links" onClick={handleNav}>
                <li className="mb-[30px] hover:cursor-pointer">Curriculum</li>
              </Link>
              <li className="mb-[30px] hover:cursor-pointer">
                <a href="https://portal.schull.io/">Login</a>
              </li>
              <Link to="/Signup" className="links" onClick={handleNav}>
                <li className="border border-yellow-500 px-[40px] py-[8px] rounded-[50px] hover:cursor-pointer hover:bg-yellow-500 hover:text-black duration-300">
                  Sign Up
                </li>
              </Link>
            </ul>
          </div>
        </section>
      </nav>
    </>
  );
}
