import React from "react";
import thumbnail1 from "../../assets/course1.png";
import thumbnail2 from "../../assets/course2.png";
import thumbnail3 from "../../assets/course3.png";
import Course from "../../components/Course";
import ScrollToTop from "../../components/ScrollToTop";

export default function Courses() {
  const courseList = [
    {
      id: 0,
      title: "DevOps  (Full Course)",
      description:
        "DevOps is a movement that embraces collaboration and cooperation among systems and application developers, system administrators, and engineers.",
      time: "6 Months",
      fee: 500000,
      dollar: 1200,
      thumbnail: thumbnail1,
      description2:
        "Amet minim mollit non deserunt ullamco. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.",
      about:
        "DevOps is an umbrella term for many software development practices and principles that work together to improve the flow of information and enhancements between developers and operators.",
      learn:
        "This course will cover core DevOps practices such as automated Testing, deployment, and management as well as emerging DevOps concepts such as microservices and container technologies.",
      outline: [
        "Linux",
        "Containerisation",
        "Cloud Computing With AWS",
        "Git",
        "Jenkins",
        "Python",
        "Ansible",
        "Kubernetes",
      ],
      prerequisites: ["Laptop", "Good Internet", "Willingness To Learn"],
    },
    {
      id: 1,
      title: "AWS Solutions Architect Associate (SAA-C03)",
      description:
        "A Solution Architect is a professional who solves complex problems in an organization by designing or modifying technology architecture and testing the integration of software in these designs for correct functionality.",
      time: "8 Weeks",
      fee: 170000,
      dollar: 408,
      thumbnail: thumbnail2,
      description2:
        "Amet minim mollit non deserunt ullamco. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.",
      about:
        "Aws solutions architect provides knowledge and skills in AWS technology, across a wide range of AWS services. The focus of this course is on the design of cost and performance-optimized solutions, demonstrating a strong understanding of the AWS Well-Architected Framework.",
      learn:
        "Design systems that are secure, reliable, high-performing, and cost-efficient Migration or designing new applications for the Cloud Design high availability, fault tolerance, and scalability Create, manage, provision, and update related resources using AWS Cloud Formation and much more.",
      outline: [
        "Design Secure Architectures With Its Services",
        "Design Resilient Architectures",
        "Design High-Performing Architectures",
        "Design Cost-Optimized Architectures",
      ],
      prerequisites: [
        "Laptop",
        "Good Internet",
        "Willingness To Learn",
        "Basic IT Knowledge",
      ],
    },
    {
      id: 2,
      title: "AWS Certified Cloud Practitioner (CLF-C02)",
      description:
        "A cloud practitioner is involved in cloud migration initiatives, reviewing workload architectures, and guiding their company teams to address high-risk issues.",
      time: "4 Weeks",
      fee: 120000,
      dollar: 288,
      thumbnail: thumbnail3,
      description2:
        "Amet minim mollit non deserunt ullamco. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.",
      about:
        "This course offers a foundational understanding of AWS Cloud concepts, services, and terminology. This is a good starting point for individuals in non-technical roles with no prior IT or cloud experience or for those with on-premises IT experience looking for basic AWS Cloud fluency.",
      learn:
        "Basic understanding of IT services and their uses on AWS Cloud Knowledge of core AWS services and use cases, billing and pricing models, security concepts, and how the cloud impacts your business.",
      outline: [
        "AWS Cloud And Its Value Proposition",
        "AWS Cloud Economics",
        "AWS Cloud Architecture Design Principleshnology",
        "AWS Shared Responsibility Model",
        "AWS Cloud Security And Compliance Concepts",
        "AWS Access Management Capabilities",
        "Identify Resources For Security Support",
        "Deploying And Operating In The AWS Cloud",
        "AWS Global Infrastructure",
        "The Core AWS Services",
        "Technology support",
        "Pricing models for AWS",
        "AWS Billing and Pricing ",
        "Billing Support",
      ],
      prerequisites: ["Laptop", "Good Internet", "Willingness To Learn"],
    },
  ];

  return (
    <>
      <ScrollToTop />
      <section className="lg:px-[81px] lg:py-[180px] md:px-[30px] md:py-[180px] px-[20px] py-[160px]">
        <section className="mb-10">
          <p className="text-[#FDCA01] font-bold text-5xl mb-2">Courses</p>
          <p className="text-[#A1A1AA] font-normal lg:w-[970px]">
            At Schull.io, we offer a wide variety of courses that can help you
            reach your goals. Whether you're looking for a vocational program
            that will lead to a career, or want to learn specific skills for a
            specific purpose, we have you covered. Check out our course
            offerings below, and find the one that will help you learn and grow
            in the direction you want to go.
          </p>
        </section>
        <section className="grid md:grid-cols-3 md:gap-4 gap-10">
          {courseList.map((item, index) => {
            return <Course key={index} {...item} />;
          })}
        </section>
      </section>
    </>
  );
}
