import React from "react";
import Main from "./Main";
import MainUS from "./MainUS";
import PuffLoader from "react-spinners/PuffLoader";
import axios from "axios";
import ScrollToTop from "../../components/ScrollToTop";

export default function Cost() {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const api_Key = "67273a00-5c4b-11ed-9204-d161c2da74ce";

  async function getUserData() {
    try {
      const response = await axios.get(
        `https://geolocation-db.com/json/${api_Key}`
      );
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(function () {
    setLoading(true);
    getUserData();
  }, []);

  if (loading) {
    return (
      <>
        <ScrollToTop />
        <div className="lg:px-[81px] lg:py-[180px] md:px-[30px] md:py-[140px] px-[20px] py-[160px]">
          <div className="flex justify-center items-center">
            <PuffLoader color={"#FDCA01"} loading={loading} size={150} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <ScrollToTop />
        <div className="">
          {data.country_name !== "Nigeria" ? <MainUS /> : <Main />}
        </div>
      </>
    );
  }
}
