import React from "react";
import Button from "../../components/Button";
import Listing from "../../components/Listing";
import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";
// import timeicon from "../../assets/time.svg";
import axios from "axios";
import { validateDateIsInPast } from "../../utils/ValidateDateIsPast";

export default function SingleCourse() {
  const [location, setLocation] = React.useState({});
  const [selected, setSelected] = React.useState(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const data = state && state.data;

  const goToSignup = () => {
    const courseData = data;
    navigate("/Signup", { state: { courseData } });
  };

  const api_Key = "67273a00-5c4b-11ed-9204-d161c2da74ce";

  async function getUserData() {
    try {
      const response = await axios.get(
        `https://geolocation-db.com/json/${api_Key}`
      );
      setLocation(response.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  React.useEffect(function () {
    getUserData();
  }, []);

  const Outline = [
    {
      id: 0,
      questions: "About this course",
      answers: [data.about],
    },
    {
      id: 1,
      questions: "What you will learn",
      answers: [data.learn],
    },
    {
      id: 2,
      questions: "Prerequisites",
      answers: data.prerequisites,
    },
    {
      id: 3,
      questions: "Courses Outline",
      answers: data.outline,
    },
  ];
  function toggle(i) {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  }
  const item = Outline.map((item, i) => {
    return (
      <div key={i} className="item lg:w-full">
        <div
          className="duration-100 hover:bg-[#fff] hover:text-black text-white flex justify-between items-center cursor-pointer border rounded-lg py-3 px-5 mb-6"
          onClick={() => toggle(i)}
        >
          <h3 className="lg:text-[20px] text-[17px] md:w-auto w-[90%] font-medium">
            {item.questions}
          </h3>
          <div className="w-[20px]">
            <p className="text-[20px]">
              {selected === i ? <IoChevronUp /> : <IoChevronDown />}
            </p>
          </div>
        </div>
        <div
          className={
            selected === i
              ? `content h-auto max-h-[999px] duration-300 py-5`
              : `content max-h-0 overflow-hidden duration-300`
          }
        >
          <ul className="text-white font-normal list-none">
            {item.answers.map((item) => {
              return (
                <div
                  key={item.length * Math.random()}
                  className=" py-2 hover:bg-gray-800 translate-y-[-20px]"
                >
                  <li className=" ml-[35px]">
                    <p className="flex items-center gap-4">
                      {/* { (
                        <span className="">
                          <img src={timeicon} className="" alt="icon" />
                        </span>
                      )} */}
                      <span className="">{item}</span>
                    </p>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    );
  });

  return (
    <>
      <ScrollToTop />
      <section className="py-[200px] md:py-[180px] lg:py-[240px]">
        <section className="lg:px-[81px] md:px-[30px] px-[20px] mb-24">
          <section className="flex flex-col-reverse md:grid lg:grid-cols-9 md:grid-cols-99 gap-5">
            <div className="">
              <p className="text-[#FFF] font-bold lg:text-5xl text-3xl mb-2">
                {data.title}
              </p>
              <p className="text-[#A1A1AA] font-normal my-5">
                {data.description}
              </p>
              <Button
                handleClick={goToSignup}
                text="Apply Now"
                custom="bg-white text-black md:w-[269px] md:h-[58px]"
              />
            </div>
            <div className="md:flex justify-end">
              <div className="lg:w-[353px] md:w-[300px] lg:h-[202px] md:h-[202px]">
                <img
                  src={data.thumbnail}
                  className="w-full h-full"
                  alt="course thumbnail"
                />
              </div>
            </div>
          </section>
        </section>
        <section className="bg-black lg:px-[81px] pt-[20px] lg:py-[20px] md:px-[10px] text-center">
          <div className="md:grid md:grid-cols-5 grid grid-cols-2 justify-evenly md:mb-4 mb-7">
            <Listing
              upper="Next Cohort Date:"
              lower={validateDateIsInPast("2024-2-26")}
            />
            <Listing upper="Duration:" lower={data.time} />
            <Listing
              upper="Tuition Fee:"
              lower={
                location.country_name !== "Nigeria"
                  ? `$${data.dollar}`
                  : `₦${data.fee}`
              }
            />
            <Listing upper="Level:" lower="Beginner" />
            <Listing upper="Learning Mode:" lower="Online Classes" />
          </div>
        </section>
        <section className="lg:px-[81px] md:px-[30px] px-[20px] py-[20px] md:py-[180px] lg:py-[100px]">
          <div className="">{item}</div>
        </section>
      </section>
    </>
  );
}
